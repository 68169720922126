export class Application{
    id:number
    job_id:number
    name:string
    surname:string
    phone_number:string
    email:string
    secret:string
    adress:string
    status:string
    cv:number
}
export class ApplicationAnswer{
    data:Application[]
}
export class OneApplicationAnswer{
    data:Application
}