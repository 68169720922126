import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Application, ApplicationAnswer, OneApplicationAnswer } from '../models/application';
import { Auth, LoginAnswer, LoginUser, RegisterUser } from '../models/auth';
import { Company, OneCompanyAnswer } from '../models/company';
import { Error } from '../models/error';
import { FileUpload, OneFileAnswer } from '../models/file';
import { Job, JobAnswer } from '../models/job';
import { Question, QuestionAnswer } from '../models/question';
import { ApplicationService } from '../services/application.service';
import { CompanyService } from '../services/company.service';
import { FileService } from '../services/file.service';
import { JobService } from '../services/job.service';
import { QuestionService } from '../services/question.service';
import { StringService } from '../services/string.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {

  public job:Job;
  public company:Company;
  public questions:Question[];
  public jobUrl:string;

  public application:Application;
  public registerUser:RegisterUser;
  public loginUser:LoginUser;
  public isAuth:boolean;
  public isRegister:boolean;
  public isLoading:boolean;
  public cv:any;
  public passwordConfirm:string;
  public existApplication:boolean;
  public isNavOpen=true;
  public error:string;


  constructor(
    private jobService:JobService,
    private companyService:CompanyService,
    private questionService:QuestionService,
    private applicationService:ApplicationService,
    private router:Router,
    private userService:UserService,
    private fileService:FileService,
    private activatedRoute:ActivatedRoute) { }


  ngOnInit() {
    this.application = new Application();
    this.registerUser = new RegisterUser();
    this.loginUser = new LoginUser();
    this.error = null;
    this.jobUrl = this.activatedRoute.snapshot.paramMap.get('jobKey');
    this.loadJob(this.jobUrl);
    this.isAuth = true;
    this.isRegister = true;
    this.existApplication = true;
    this.isLoading = false;
    
  }

  private loadJob(jobUrl:string){
    this.jobService.getJob(jobUrl).subscribe((result:JobAnswer)=>{
      this.job = result.data[0];
      this.loadCompany(this.job.company_id);
      this.loadQuestions(this.job.id);
      this.application.job_id = this.job.id;
      console.log(this.application);
    });
  }

  private loadCompany(id:number){
    this.companyService.getCompanyWithouthToken(id).subscribe((result:OneCompanyAnswer)=>{
      this.company = result.data;
    });
  }

  private loadQuestions(jobId:number){
    this.questionService.getQuestions(jobId).subscribe((result:QuestionAnswer)=>{
      this.questions = result.data;
    });
  }

  cvChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        this.cv = reader.result;
    };
}

  public submit(){
    this.error = null;  
    this.isLoading=true;
    if(this.application.name == undefined){
      this.showError("Name is required!");
      this.isLoading = false;
      return;
    }
    if(this.application.surname == undefined){
      this.showError("Surname is required!");
      this.isLoading = false;
      return;
    }
    if(this.application.email==undefined){
      this.showError("Email is required!");
      this.isLoading = false;
      return;
    }
    if(this.application.phone_number==undefined){
      this.showError("Phone is required!");
      this.isLoading = false;
      return;
    }
    if(this.job.cv_required && this.cv == undefined){
      this.showError("CV is required!");
      this.isLoading = false;
      return;
    }
    this.application.secret = localStorage.getItem('external_user_id');
    
    if(this.job.cv_required){
      let fileUpload = new FileUpload();
      fileUpload.data = this.cv;
      fileUpload.filename_disk = "cv.pdf";
      fileUpload.filename_download = "cv.pdf";
      let token = localStorage.getItem('token');
      this.fileService.uploadFile(fileUpload,token).subscribe((result:OneFileAnswer)=>{
        this.application.cv = result.data.id;
        this.sendApplication();
      });
    }else{
      this.sendApplication();
    }
  }

  private sendApplication(){
    
    let token = localStorage.getItem('token');
    this.applicationService.createApplication(this.application,token).subscribe((result:OneApplicationAnswer)=>{
      this.router.navigate([this.company.company_url+"/job/"+this.job.url+"/"+result.data.secret]);
      this.isLoading = false;
    },(error:Error)=>{
      if(error.error.error.code==204){
        this.showError("Application already exist");
        this.router.navigate([this.company.company_url+"/job/"+this.job.url+"/"+this.application.secret]);
      }
      console.log(error);
    });
  }

  private showError(error:string){
    this.isLoading = false;
    this.error = error;
  }

  public register(){
    this.isLoading=true;
    if(this.registerUser.first_name == undefined){
      this.showError("Name is Required");
      return;
    }
    if(this.registerUser.last_name == undefined){
      this.showError("Surname is Required");
      return;
    }
    if(this.registerUser.email == undefined){
      this.showError("Email is Required");
      this.isLoading = false;
      return;
    }
    if(this.registerUser.password == undefined){
      this.showError("Password is Required");
      return;
    }
    if(this.registerUser.password != this.passwordConfirm){
      this.showError("Passwords are diffrent");
      return;
    }
    this.registerUser.role = 1;
    this.registerUser.status = "active";
    this.userService.createAccount(this.registerUser).subscribe(result=>{
      this.loginUser = new LoginUser();
      this.loginUser.email = this.registerUser.email;
      this.loginUser.password = this.registerUser.password;
      this.loginRequest();
    },(error:Error)=>{
      this.isLoading = false;
      let code = error.error.error.code;
      if(code==204){
        this.showError("Email is already used! Try to login.")
      }
      if(code==4){
        this.showError("Email is not valid!")
      }
    });
  }
  public login(){
    this.isLoading = true;
    if(this.loginUser.email == undefined){
      this.showError("Email is Required");
      return;
    }
    if(this.loginUser.password == undefined){
      this.showError("Password is Required");
      return;
    }
    this.loginRequest();
  }

  private loginRequest(){
    this.userService.login(this.loginUser).subscribe((result:LoginAnswer)=>{
      this.parseLoginData(result);
      this.isLoading = false;
    },(error:Error)=>{
      this.isLoading = false;
      let code = error.error.error.code;
      if(code==100){
        this.showError("User credentials are wrong!");
      }
    });
  }

  private parseLoginData(loginData:LoginAnswer){
    localStorage.setItem('token', loginData.data.token);
    localStorage.setItem('external_user_id', loginData.data.user.external_id);
    localStorage.setItem('user_id', loginData.data.user.id.toString());
    this.application.name = loginData.data.user.first_name;
    this.application.surname = loginData.data.user.last_name;
    this.application.email = loginData.data.user.email;
    this.isAuth = false;
    this.checkIfApplicationExist(loginData.data.user.id);
  }
  private checkIfApplicationExist(userId:number){
    let token = localStorage.getItem('token');
    this.applicationService.getApplication(userId,this.job.id,token).subscribe((result:ApplicationAnswer)=>{
      if(result.data.length>0){
        this.router.navigate([this.company.company_url+"/job/"+this.job.url+"/"+result.data[0].secret]);
      }else{
        this.existApplication = false;
      }
    })
  }
  openNav() {
    document.getElementById("mySidebar").style.width = "50%";
    document.getElementById("maincontent").style.width = "50%";
    this.isNavOpen = true;
  }
  
  closeNav() {
    document.getElementById("mySidebar").style.width = "4%";
    document.getElementById("maincontent").style.width = "100%";
    this.isNavOpen = false;
  }
}
