import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { JobAnswer, JobCompany, JobCompanyAnswer } from '../models/job';

@Injectable({
  providedIn: 'root'
})
export class JobService {
  

  constructor(private httpClient:HttpClient) { }

  public getJob(url:string):Observable<JobAnswer>{
    return this.httpClient.get<JobAnswer>(environment.API_BASE+"/items/jobs?filter[url][eq]="+url);
  }
  public getJobs():Observable<JobCompanyAnswer>{
    return this.httpClient.get<JobCompanyAnswer>(environment.API_BASE+"/items/jobs?fields=*,company_id.*");
  }
  public searchJobs(search:string):Observable<JobCompanyAnswer>{
    return this.httpClient.get<JobCompanyAnswer>(environment.API_BASE+"/items/jobs?fields=*,company_id.*&q="+search);
  }
  getJobsForCompany(companyId: number):Observable<JobCompanyAnswer> {
    return this.httpClient.get<JobCompanyAnswer>(environment.API_BASE+"/items/jobs?filter[company_id][eq]="+companyId+"&fields=*,company_id.*");
  }
  searchJobsForCompany(companyId: number,search:string):Observable<JobCompanyAnswer> {
    return this.httpClient.get<JobCompanyAnswer>(environment.API_BASE+"/items/jobs?filter[company_id][eq]="+companyId+"&q="+search+"&fields=*,company_id.*");
  }
  
  
}
