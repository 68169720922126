import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company, CompanyAnswer } from '../models/company';
import { Job, JobAnswer, JobCompany, JobCompanyAnswer } from '../models/job';
import { CompanyService } from '../services/company.service';
import { JobService } from '../services/job.service';

@Component({
  selector: 'app-job-overview-company',
  templateUrl: './job-overview-company.component.html',
  styleUrls: ['./job-overview-company.component.css']
})
export class JobOverviewCompanyComponent implements OnInit {

  public company: Company;
  public jobs: JobCompany[];
  public selectedJob:JobCompany;
  public comapnyUrl: string;
  public search: string;
  constructor(
    private jobSevice: JobService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService
  ) { }

  ngOnInit() {
    this.comapnyUrl = this.activatedRoute.snapshot.paramMap.get('companyUrl');
    this.loadCompany(this.comapnyUrl);
  }
  private loadCompany(companyUrl: string) {
    this.companyService.getCompanyByUrl(companyUrl).subscribe((result: CompanyAnswer) => {
      this.company = result.data[0];
      console.log(this.company);
      this.loadJobs(this.company.id);
    });
  }
  private loadJobs(companyId: number) {
    this.jobSevice.getJobsForCompany(companyId).subscribe((result: JobCompanyAnswer) => {
      this.jobs = result.data;
    });
  }
  public searchJobs() {
  if(this.search=="" || this.search==undefined){
     return;
   }
    this.jobSevice.searchJobsForCompany(this.company.id, this.search).subscribe((result: JobCompanyAnswer) => {
      this.jobs = result.data;
    });
  }
  public openJob(job:JobCompany){
    this.router.navigate([job.company_id.company_url+"/job/"+job.url])
  }
  public selectJob(job:JobCompany){
    this.selectedJob = job;
   }
}
