import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OneCompanyAnswer } from '../models/company';
import { FileUpload, OneFileAnswer } from '../models/file';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private httpClient:HttpClient) { }

  public uploadFile(fileUpload:FileUpload,token:string):Observable<OneFileAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.post<OneFileAnswer>(environment.API_BASE+"/files",fileUpload,{headers:authHeaders});
  }
  private getAuthorizationHeader(token): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}
