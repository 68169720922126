export class Answer{
    id:number
    question_id:number
    application_id:number
    comment:string
    answer_audio:number
}

export class AnswerResult{
    data:Answer[]
}

export class OneAnswerResult{
    data:Answer
}