import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Answer } from '../models/answer';

@Injectable({
  providedIn: 'root'
})
export class AnswerService {
  createAnswer(answer: Answer, token: string):Observable<any> {
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.post<any>(environment.API_BASE+"/items/answers",answer,{headers:authHeaders});
  }

  constructor(private httpClient:HttpClient) { }

  public getAnswers(applicationId:number,questionId:number,token):Observable<any>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.get<any>(environment.API_BASE+"/items/answers?filter[application_id][eq]="+applicationId+"&filter[question_id][eq]="+questionId+"&fields=*,answer_audio.*",{headers:authHeaders});
  }
  private getAuthorizationHeader(token): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}
