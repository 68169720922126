import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JobComponent } from './job/job.component';
import { HttpClientModule } from '@angular/common/http';
import { ApplicationComponent } from './application/application.component';
import { FormsModule } from '@angular/forms';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { JobOverviewComponent } from './job-overview/job-overview.component';
import { JobOverviewCompanyComponent } from './job-overview-company/job-overview-company.component';
@NgModule({
  declarations: [
    AppComponent,
    JobComponent,
    ApplicationComponent,
    JobOverviewComponent,
    JobOverviewCompanyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxQRCodeModule 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
