import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Auth, LoginAnswer, LoginUser, RegisterUser } from '../models/auth';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient:HttpClient) { }

  public createAccount(user:RegisterUser):Observable<any>{
    return this.httpClient.post<any>(environment.API_BASE+"/users",user);
  }
  public login(user:LoginUser):Observable<LoginAnswer>{
    return this.httpClient.post<LoginAnswer>(environment.API_BASE+"/auth/authenticate",user);
  }
}
