import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Application, ApplicationAnswer, OneApplicationAnswer } from '../models/application';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  

  constructor(private httpClient:HttpClient) { }

  getApplication(userId: number, jobId: number, token: string):Observable<ApplicationAnswer> {
    let authHeaders = this.getAuthorizationHeader(token);
    console.log(environment.API_BASE+"/items/applications?filter[owner][eq]="+userId+"&filter[job_id][eq]="+jobId+"&fields=*,cv.*")
    return this.httpClient.get<ApplicationAnswer>(environment.API_BASE+"/items/applications?filter[owner][eq]="+userId+"&filter[job_id][eq]="+jobId+"&fields=*,cv.*",{headers: authHeaders});
  }

  public createApplication(application:Application,token:string):Observable<OneApplicationAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.post<OneApplicationAnswer>(environment.API_BASE+"/items/applications",application,{headers: authHeaders});
  }
  private getAuthorizationHeader(token): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}
