import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from '../models/company';
import { Job, JobAnswer, JobCompany, JobCompanyAnswer } from '../models/job';
import { JobService } from '../services/job.service';

@Component({
  selector: 'app-job-overview',
  templateUrl: './job-overview.component.html',
  styleUrls: ['./job-overview.component.css']
})
export class JobOverviewComponent implements OnInit {

  public jobs:JobCompany[];
  public search:string;
  public selectedJob:JobCompany;
  constructor(
    private jobSevice:JobService,
    private router:Router
  ) { }

  ngOnInit() {
    this.loadJobs();
  }
  
  private loadJobs(){
    this.jobSevice.getJobs().subscribe((result:JobCompanyAnswer)=>{
      this.jobs = result.data;
    });
  }

  public openJob(job:JobCompany){
    this.router.navigate([job.company_id.company_url+"/job/"+job.url])
  }
 public searchJobs(){
   if(this.search=="" || this.search==undefined){
     return;
   }
   this.jobSevice.searchJobs(this.search).subscribe((result:JobCompanyAnswer)=>{
    this.jobs = result.data;
   });
 }
 public selectJob(job:JobCompany){
  this.selectedJob = job;
 }
}
