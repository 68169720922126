import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CompanyAnswer, OneCompanyAnswer } from '../models/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  

  constructor(private httpClient:HttpClient) { }

  public getCompany(id:number,token:string):Observable<OneCompanyAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.get<OneCompanyAnswer>(environment.API_BASE+"/items/companies/"+id+"?fields=*,logo.*",{headers:authHeaders});
  }
  public getCompanyWithouthToken(id:number):Observable<OneCompanyAnswer>{
    return this.httpClient.get<OneCompanyAnswer>(environment.API_BASE+"/items/companies/"+id+"?fields=*");
  }
  getCompanyByUrl(companyUrl: string):Observable<CompanyAnswer> {
    return this.httpClient.get<CompanyAnswer>(environment.API_BASE+"/items/companies?filter[company_url][eq]="+companyUrl);
  }
  private getAuthorizationHeader(token): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}
