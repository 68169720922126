export class DirectusFile {
    id: number;
    title: string;
    type: string;
    uploaded_by: string;
    uploaded_on: string;
    width: number;
    height: number;
    duration: string;
    description: string;
    data: Data;
  }
  export class Data {
    id: number;
    full_url: string;
    thumbnails:Thumbnail[];
  }
  export class Thumbnail{
    url:string;
  }
  
  export class ImageData {
    image: any;
    image_path: any;
    image_name: any;
  }
  export class FileData {
    file: any;
    file_path: any;
    file_name: any;
    type: string;
  }
  
  export class FileSingleAnswer {
    data: DirectusFile;
  }
  
  export class FileAnswer {
    data: DirectusFile[]
  }

  export class FileUpload{
    data:string
    filename_disk:string
    filename_download:string
  }

  export class OneFileAnswer{
    data:DirectusFile
  }