import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApplicationComponent } from './application/application.component';
import { JobOverviewCompanyComponent } from './job-overview-company/job-overview-company.component';
import { JobOverviewComponent } from './job-overview/job-overview.component';
import { JobComponent } from './job/job.component';

const routes: Routes = [
  { path: ':companyUrl/job/:jobKey/:applicationSecret', component: JobComponent},
  {
    path: ':companyUrl/job/:jobKey', component: ApplicationComponent
  },
  {
    path: ':companyUrl', component: JobOverviewCompanyComponent
  },
  {
    path: '', component: JobOverviewComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
