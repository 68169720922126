import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels, NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { Company, CompanyAnswer, OneCompanyAnswer } from '../models/company';
import { Job, JobAnswer } from '../models/job';
import { Question, QuestionAnswer } from '../models/question';
import { CompanyService } from '../services/company.service';
import { JobService } from '../services/job.service';
import { QuestionService } from '../services/question.service';
import * as RecordRTC from 'recordrtc';
import { FileUpload, OneFileAnswer } from '../models/file';
import { FileService } from '../services/file.service';
import { AnswerService } from '../services/answer.service';
import { ApplicationService } from '../services/application.service';
import { Application, ApplicationAnswer } from '../models/application';
import { Answer, AnswerResult } from '../models/answer';
import { AudioInput } from '../models/audio';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css']
})

export class JobComponent implements OnInit {

  public job: Job;
  public company: Company;
  public application: Application;
  public questions: Question[];
  public questionIndex: number;
  public audioUpload = new FileUpload();
  public isLoading = false;
  public isNavOpen = true;
  public jobUrl:string;


  //QRCODE
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value: string = window.location.href;

  //RECORDING
  record;
  recording = false;
  recordingTest =false;
  url;
  testUrl;
  error;
  preparingAudio = false;
  //for timer
  time: number = 0;
  interval;
  displayTime;

  //audioinput
  audioInputs: AudioInput[];
  selectedDevice:string;
  constructor(
    private jobService: JobService,
    private companyService: CompanyService,
    private questionService: QuestionService,
    private domSanitizer: DomSanitizer,
    private fileService: FileService,
    private answerService: AnswerService,
    private applicationService: ApplicationService,
    private activatedRoute:ActivatedRoute) { }

  ngOnInit() {
    this.questionIndex = 0;
    this.audioInputs = new Array<AudioInput>();
    this.getDevices();
    this.jobUrl = this.activatedRoute.snapshot.paramMap.get('jobKey');
    this.loadJob(this.jobUrl);
  }

  private loadJob(jobUrl:string) {
    this.jobService.getJob(jobUrl).subscribe((result: JobAnswer) => {
      this.job = result.data[0];
      let userId = Number(localStorage.getItem('user_id'));
      this.loadApplication(userId, this.job.id);
    });
  }

  private loadCompany(id: number) {
    let token = localStorage.getItem('token');
    this.companyService.getCompany(id, token).subscribe((result: OneCompanyAnswer) => {
      this.company = result.data;
    });
  }

  private loadQuestions(jobId: number) {
    this.questionService.getQuestions(jobId).subscribe((result: QuestionAnswer) => {
      this.questions = result.data;
      console.log(this.questions);
      this.questions.forEach(question => {
        this.loadAnswers(question.id);
      });
    });
  }
  private loadApplication(userId: number, jobId: number) {
    let token = localStorage.getItem('token');
    console.log(userId);
    console.log(jobId);
    this.applicationService.getApplication(userId, jobId, token).subscribe((result: ApplicationAnswer) => {
      console.log(result);
      this.application = result.data[0];
      this.loadCompany(this.job.company_id);
      this.loadQuestions(this.job.id);
    })
  }
  private loadAnswers(questionId: number) {
    let token = localStorage.getItem('token');
    console.log(this.application);
    this.answerService.getAnswers(this.application.id, questionId, token).subscribe((result: AnswerResult) => {
      this.questions.forEach(question => {
        if (question.id == questionId) {
          question.answer = result.data[0];
          this.countQuestionAnswersAndUpdateIndex();
        }
      });
    });
  }
  private countQuestionAnswersAndUpdateIndex() {
    let index = 0;
    this.questions.forEach(question => {
      if (question.answer !== undefined) {
        index++;
      }
    });
    this.questionIndex = index;
  }
  public sendQuestion() {
    this.isLoading = true;
    this.audioUpload.filename_disk = "audiens.wav";
    this.audioUpload.filename_download = "audiens.wav";
    let token = localStorage.getItem('token');
    this.fileService.uploadFile(this.audioUpload, token).subscribe((result: OneFileAnswer) => {
      let answer = new Answer();
      answer.answer_audio = result.data.id;
      answer.question_id = this.questions[this.questionIndex].id;
      answer.application_id = this.application.id;
      let token = localStorage.getItem('token');
      this.answerService.createAnswer(answer, token).subscribe(result => {
        this.loadAnswers(answer.question_id);
        this.url = null;
      }, error => {

      }, () => {
        this.isLoading = false;
      })
    });
  }


  /*Recording */
  startTimer() {
    console.log(this.questions[this.questionIndex].max_length);
    if(this.questions[this.questionIndex].max_length==null){
      this.time = 0;
      this.interval = setInterval(() => {
        this.time++;
        this.displayTime = this.transformTime(this.time)
      }, 1000);
    }else{
      this.time = this.questions[this.questionIndex].max_length;
      this.interval = setInterval(() => {
        this.time--;
        this.displayTime = this.transformTime(this.time);
        if(this.time<=0){
          this.stopRecording();
        }
      }, 1000);
    }
    
  }
  transformTime(value: number): string {
    const minutes: number = Math.floor(value / 60);
    let seconds = (value - minutes * 60);
    let secondString;
    if (seconds == 0) {
      secondString = "00";
    } else {
      secondString = seconds.toString();
    }
    return minutes + ':' + secondString;
  }
  pauseTimer() {
    clearInterval(this.interval);
  }
  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
  initiateRecording() {
    this.startTimer();
    this.url = null;
    this.recording = true;
    let mediaConstraints = {
      audio: {
      deviceId:this.selectedDevice}
    };

    navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }
  
  successCallback(stream) {
    var options = {
      numberOfAudioChannels: 1,
      //sampleRate: 44100
      desiredSampRate: 12000,
      bufferSize: 4096
    };
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
  }
  initiateRecordingTest() {
    this.startTimer();
    this.url = null;
    this.recordingTest = true;
    let mediaConstraints = {
      audio: {
      deviceId:this.selectedDevice}
    };

    navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallbackTest.bind(this), this.errorCallback.bind(this));
  }
  
  successCallbackTest(stream) {
    var options = {
      numberOfAudioChannels: 1,
      //sampleRate: 44100
      desiredSampRate: 12000,
      bufferSize: 4096
    };
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
  }

  stopRecording() {
    this.preparingAudio = true;
    this.pauseTimer();
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));
  }
  /**
  * processRecording Do what ever you want with blob
  * @param  {any} blob Blog
  */
  processRecording(blob) {
    this.url = URL.createObjectURL(blob);
    this.preparingAudio = false;
    console.log(blob);
    this.audioToBase64(blob);
  }

  stopRecordingTest() {
    this.preparingAudio = true;
    this.pauseTimer();
    this.recordingTest = false;
    this.record.stop(this.processRecordingTest.bind(this));
  }
  /**
  * processRecording Do what ever you want with blob
  * @param  {any} blob Blog
  */
  processRecordingTest(blob) {
    this.testUrl = URL.createObjectURL(blob);
    this.preparingAudio = false;
    console.log(blob);
    this.audioToBase64(blob);
  }
  audioToBase64(blob) {
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    let that = this;
    reader.onloadend = function () {
      var base64data = reader.result;
      that.audioUpload.data = base64data.toString();
    }
  }
  /**
  * Process Error.
  */
  errorCallback(error) {
    this.error = 'Can not play audio in your browser';
  }

  changeMicrophone(value:string){
    this.selectedDevice = value;
  }
  getDevices() {
    /*navigator.mediaDevices.enumerateDevices()
    .then(this.gotDevices);*/
    this.audioInputs = new Array<AudioInput>(); 
    navigator.mediaDevices.enumerateDevices().then(value => {
      this.gotDevices(value, this);
    });
  }

  gotDevices(deviceInfos, that) {
    
    let audioInputs = new Array<AudioInput>();
    let count = 1;
    for (var i = 0; i !== deviceInfos.length; ++i) {
      var deviceInfo = deviceInfos[i];
      if (deviceInfo.kind === 'audioinput') {
        let audioInput = new AudioInput();
        audioInput.text = deviceInfo.label || 'Microphone ' + (count);
        audioInput.value = deviceInfo.deviceId;
        console.log(audioInputs);
        that.audioInputs.push(audioInput);
        count++;

      }

    }
    console.log(that.audioInputs);
}

openNav() {
  document.getElementById("mySidebar").style.width = "50%";
  document.getElementById("maincontent").style.width = "50%";
  this.isNavOpen = true;
}

closeNav() {
  document.getElementById("mySidebar").style.width = "4%";
  document.getElementById("maincontent").style.width = "100%";
  this.isNavOpen = false;
}

}
